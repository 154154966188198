export default {
  state: {
    changeTableStatus: {
      id: "",
      title: "",
      subtitle: "",
      type: "",
      route: "",
    },
    changeTableModalStatus: false,
  },

  getters: {
    getChangeTableStatusModalInfos(state) {
      return {
        status: state.changeTableModalStatus,
        data: state.changeTableStatus,
      };
    },
  },

  mutations: {
    setNewTableInformations(state, infos) {
      state.changeTableModalStatus = infos.status;
      let isData = infos.data !== null;

      state.changeTableStatus = {
        id: isData ? infos.data.id : "",
        title: isData ? infos.data.title : "",
        subtitle: isData ? infos.data.subtitle : "",
        type: isData ? infos.data.type : "",
        route: isData ? infos.data.route : "",
      };
    },
  },

  actions: {
    changeTableInformations(context, infos) {
      context.commit("setNewTableInformations", infos);
    },
  },
};
