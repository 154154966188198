export default {
  state: {
    snackbar: {
      status: false,
      type: "",
      icon: "",
      title: "",
      message: "",
    },

    alertModal: {
      status: false,
      type: "error",
      icon: "mdi-alert",
      title: "",
      message: "",
    },
  },

  getters: {
    getSnackbarData(state) {
      return state.snackbar;
    },

    getAlertModalData(state) {
      return state.alertModal;
    },
  },

  mutations: {
    setSnackbarData(state, data) {
      state.snackbar = {
        status: data[0],
        type: data[1],
        icon: data[2],
        title: data[3],
        message: data[4],
      };
    },

    setAlertModalData(state, data) {
      state.alertModal = {
        status: data[0],
        type: data[1],
        icon: data[2],
        title: data[3],
        message: data[4],
      };
    },

    setDisableAlertModalData(state) {
      state.alertModal.status = false;
    },
  },

  actions: {
    changeSnackbarData(context, data) {
      context.commit("setSnackbarData", data);
    },

    changeAlertModalData(context, data) {
      context.commit("setAlertModalData", data);
    },

    disabledAlertModal(context) {
      context.commit("setDisableAlertModalData");
    },
  },
};
