export default {
  state: {
    typeOfOccurrencesData: {},
    typeOfOccurrencesModalType: "CREATE",
    typeOfOccurrencesModalStatus: false,
  },

  getters: {
    getTypeOfOccurrencesModalInfos(state) {
      return {
        data: state.typeOfOccurrencesData,
        type: state.typeOfOccurrencesModalType,
        status: state.typeOfOccurrencesModalStatus,
      };
    },
  },

  mutations: {
    setTypeOfOccurrencesModalInfos(state, infos) {
      let isData = infos.data !== null;

      state.typeOfOccurrencesData = {
        id: isData ? infos.data.id : "",
        name: isData ? infos.data.name : "",
      };

      state.typeOfOccurrencesModalType = infos.type;
      state.typeOfOccurrencesModalStatus = infos.status;
    },
  },

  actions: {
    changeTypeOfOccurrencesModalInfos(context, infos) {
      context.commit("setTypeOfOccurrencesModalInfos", infos);
    },
  },
};
