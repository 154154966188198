export default {
  state: {
    userModalData: {
      id: "",
      name: "",
      cpf: "",
      email: "",
      profile: "",
      sector: "",
      occupation: "",
      photo: "",
      registration: "",
      unity: "",
    },
    userModalType: "CREATE",
    userModalStatus: false,
  },

  getters: {
    getUserModalInfos(state) {
      return {
        data: state.userModalData,
        type: state.userModalType,
        status: state.userModalStatus,
      };
    },
  },

  mutations: {
    setUserModalInfos(state, infos) {
      let isData = infos.data !== null;

      state.userModalData = {
        id: isData ? infos.data.id : "",
        name: isData ? infos.data.name : "",
        cpf: isData ? infos.data.cpf : "",
        email: isData ? infos.data.email : "",
        profile: isData ? parseInt(infos.data.profile_id) : "",
        sector: isData ? parseInt(infos.data.sector_id) : "",
        occupation: isData ? parseInt(infos.data.occupation_id) : "",
        photo: isData ? infos.data.photo : "",
        registration: isData ? infos.data.registration : "",
        unity: isData ? parseInt(infos.data.unity_id) : "",
      };

      state.userModalType = infos.type;
      state.userModalStatus = infos.status;
    },
  },

  actions: {
    changeUserModalInfos(context, infos) {
      context.commit("setUserModalInfos", infos);
    },
  },
};
