import axios from "axios";

const instance = axios.create({});

// Declarando rota default
instance.defaults.baseURL = process.env.VUE_APP_URL;

// Interceptando requisição
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("userToken");
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

// Interceptando resposta
instance.interceptors.response.use(
  (config) => {
    localStorage.setItem("userToken", config.headers.authorization);
    return config;
  },

  (error) => {
    if (error.response.data.error == "Unauthenticated.") {
      alert("Você não está autenticado!");
      window.location.href = "/";
    }

    if (error.response.status >= 401) {
      if (typeof error.response.data.details != "string") {
        Object.values(error.response.data.details).map((value) => {
          throw value.toString();
        });
      } else {
        throw error.response.data.details;
      }
    }
  }
);

export default instance;
