export default {
  state: {
    scheduleData: {},
    scheduleModalType: "CREATE",
    scheduleModalStatus: false,

    fillScheduleData: {
      professionalCosis: { id: "", userId: "" },
      professionalCoinfra: { id: "", userId: "" },
    },
    fillScheduleModalType: "CREATE",
    fillScheduleModalStatus: false,
  },

  getters: {
    getScheduleModalInfos(state) {
      return {
        data: state.scheduleData,
        type: state.scheduleModalType,
        status: state.scheduleModalStatus,
      };
    },

    getFillScheduleModalInfos(state) {
      return {
        data: state.fillScheduleData,
        type: state.fillScheduleModalType,
        status: state.fillScheduleModalStatus,
      };
    },
  },

  mutations: {
    setScheduleModalInfos(state, infos) {
      let isData = infos.data !== null;
      let statusValidate = "";

      if (isData) {
        statusValidate = infos.data.validated === "0" ? "P" : "V";
      }

      state.scheduleData = {
        id: isData ? infos.data.id : "",
        competence: isData ? infos.data.competence : "",
        unity: isData ? parseInt(infos.data.unity_id) : "",
        status: isData ? statusValidate : "",
      };

      state.scheduleModalType = infos.type;
      state.scheduleModalStatus = infos.status;
    },

    setFillScheduleModalInfos(state, infos) {
      let isData = infos.type === "EDIT";
      let isFill = infos.type === "FILL" && infos.data !== null;

      let cosis = "";
      let coinfra = "";

      if (isData) {
        if (infos.data.more.length > 0) {
          cosis = infos.data.more.find((data) => data.user.sector.id === 1);
          coinfra = infos.data.more.find((data) => data.user.sector.id === 2);
        }
      }

      state.fillScheduleData = {
        id: isData ? infos.data.id : "",
        date: isData || isFill ? infos.data.date : "",
        professionalCosis:
          isData && cosis
            ? { id: cosis.id, userId: cosis.user.id }
            : { id: "", userId: "" },
        professionalCoinfra:
          isData && coinfra
            ? { id: coinfra.id, userId: coinfra.user.id }
            : { id: "", userId: "" },
      };

      state.fillScheduleModalStatus = infos.status;
      state.fillScheduleModalType = infos.type;
    },
  },

  actions: {
    changeScheduleModalInfos(context, infos) {
      context.commit("setScheduleModalInfos", infos);
    },

    changeFillScheduleModalInfos(context, infos) {
      context.commit("setFillScheduleModalInfos", infos);
    },
  },
};
