export default {
  state: {
    avatarAccountModalStatus: false,
    tabActive: 0,
  },

  getters: {
    getAvatarAccountModalStatus(state) {
      return state.avatarAccountModalStatus;
    },

    getAccountTabStatus(state) {
      return state.tabActive;
    },
  },

  mutations: {
    setAvatarAccountModalStatus(state, status) {
      state.avatarAccountModalStatus = status;
    },

    setAccountTabStatus(state, status) {
      state.tabActive = status;
    },
  },

  actions: {
    changeAvatarAccountModalStatus(context, status) {
      context.commit("setAvatarAccountModalStatus", status);
    },

    changeAccountTabStatus(context, status) {
      context.commit("setAccountTabStatus", status);
    },
  },
};
