import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import guard from "@/guard/routeGuard.js";
const { validationRoute } = guard;

Vue.use(VueRouter);

const routes = [
  // LOGIN
  {
    path: "/",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/components/modules/login/Login.vue"),
      },
    ],
  },

  // DASHBOARD
  {
    path: "/dashboard",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        permission: "dashboard",
        component: () => import("@/components/modules/dashboard/Dashboard.vue"),
      },
    ],
  },

  // ESCALAS
  {
    path: "/escalas",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de Escalas",
        permission: "list_schedule",
        component: () =>
          import("@/components/modules/schedule/GridSchedule.vue"),
      },
      {
        path: "/escala/preencher",
        name: "Preencher escala",
        permission: "list_schedule",
        component: () =>
          import(
            "@/components/modules/schedule/fill-schedule/GridFillSchedule.vue"
          ),
      },
    ],
  },

  // OCORRÊNCIAS
  {
    path: "/ocorrencias",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de ocorrências",
        permission: "list_type_occurrences",
        component: () =>
          import("@/components/modules/occurrences/GridOccurrences.vue"),
      },
      {
        path: "/ocorrencia/historico",
        name: "Histórico da ocorrência",
        permission: "list_type_occurrences",
        component: () =>
          import(
            "@/components/modules/occurrences/historic/OccurrenceHistory.vue"
          ),
      },
    ],
  },

  // PERFIS
  {
    path: "/perfis",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de perfis",
        permission: "list_perfil",
        component: () =>
          import("@/components/modules/profiles/GridProfiles.vue"),
      },
    ],
  },

  // SISTEMAS
  {
    path: "/sistemas",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de sistemas",
        permission: "list_system",
        component: () => import("@/components/modules/systems/GridSystems.vue"),
      },
    ],
  },

  // TIPO DE OCORRÊNCIAS
  {
    path: "/tipo-ocorrencias",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de tipo de ocorrências",
        permission: "list_type_occurrences",
        component: () =>
          import(
            "@/components/modules/type-of-occurrences/GridTypeOfOccurrences.vue"
          ),
      },
    ],
  },

  // USUARIOS
  {
    path: "/usuarios",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de usuários",
        permission: "cad_usuario",
        component: () => import("@/components/modules/users/GridUsers.vue"),
      },
    ],
  },

  // MINHA CONTA
  {
    path: "/minha-conta",
    beforeEnter: validationRoute,
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Minha Conta",
        permission: "account",
        component: () => import("@/components/modules/my-account/Account.vue"),
      },
    ],
  },

  // REDEFINIR SENHA
  {
    path: "/esqueci-minha-senha",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Esqueci minha senha",
        component: () =>
          import("@/components/modules/my-account/password/Password.vue"),
      },
    ],
  },
];

store.state.localRoutes = routes;

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
