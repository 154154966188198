export default {
  state: {
    systemData: {},
    systemModalType: "CREATE",
    systemModalStatus: false,
  },

  getters: {
    getSystemModalInfos(state) {
      return {
        data: state.systemData,
        type: state.systemModalType,
        status: state.systemModalStatus,
      };
    },
  },

  mutations: {
    setSystemModalInfos(state, infos) {
      let isData = infos.data !== null;

      state.systemData = {
        id: isData ? infos.data.id : "",
        name: isData ? infos.data.name : "",
        abbreviation: isData ? infos.data.abbreviation : "",
      };

      state.systemModalType = infos.type;
      state.systemModalStatus = infos.status;
    },
  },

  actions: {
    changeSystemModalInfos(context, infos) {
      context.commit("setSystemModalInfos", infos);
    },
  },
};
