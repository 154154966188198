export default {
  state: {
    userPassword: [],
    userPasswordStep: "SendEmail",
  },

  getters: {
    getUserPasswordInfos(state) {
      return {
        data: state.userPassword,
        step: state.userPasswordStep,
      };
    },
  },

  mutations: {
    setUserPasswordInfos(state, infos) {
      if (infos.data !== undefined) {
        infos.data !== null
          ? state.userPassword.push(infos.data)
          : (state.userPassword = []);
      }

      state.userPasswordStep = infos.step;
    },
  },

  actions: {
    changeUserPasswordInfos(context, infos) {
      context.commit("setUserPasswordInfos", infos);
    },
  },
};
