export default {
  state: {
    occurrenceHistoryData: {},
    occurrenceHistoryModalType: "CREATE",
    occurrenceHistoryModalStatus: false,
  },

  getters: {
    getOccurrenceHistoryModalInfos(state) {
      return {
        data: state.occurrenceHistoryData,
        type: state.occurrenceHistoryModalType,
        status: state.occurrenceHistoryModalStatus,
      };
    },
  },

  mutations: {
    setOccurrenceHistoryModalInfos(state, infos) {
      let isData = infos.data !== null;

      state.occurrenceHistoryData = {
        id: isData ? infos.data.id : "",
        flag: isData ? parseInt(infos.data.flag_resolution) : "",
        description: isData ? infos.data.description : "",
        file: isData ? infos.data.url : [],
      };

      state.occurrenceHistoryModalType = infos.type;
      state.occurrenceHistoryModalStatus = infos.status;
    },
  },

  actions: {
    changeOccurrenceHistoryModalInfos(context, infos) {
      context.commit("setOccurrenceHistoryModalInfos", infos);
    },
  },
};
