export default {
  state: {
    occurrenceData: {},
    occurrenceModalType: "CREATE",
    occurrenceModalStatus: false,
  },

  getters: {
    getOccurrenceModalInfos(state) {
      return {
        data: state.occurrenceData,
        type: state.occurrenceModalType,
        status: state.occurrenceModalStatus,
      };
    },
  },

  mutations: {
    setOccurrenceModalInfos(state, infos) {
      let isData = infos.data !== null;

      state.occurrenceData = {
        id: isData ? infos.data.id : "",
        user: isData ? parseInt(infos.data.user_id) : "",
        statusOfOccurence: isData ? parseInt(infos.data.status_id) : "",
        typeOfOccurrence: isData ? parseInt(infos.data.type_occurrence_id) : "",
        system: isData ? parseInt(infos.data.system_id) : "",
        requestUnity: isData ? parseInt(infos.data.unity_id) : "",
        requestCivilServant: isData ? infos.data.server : "",
        number: isData ? infos.data.number : "",
        phone: isData ? infos.data.phone : "",
        email: isData ? infos.data.email : "",
        description: isData ? infos.data.description : "",
        registration: isData ? infos.data.registration : "",
        file: [],
      };

      state.occurrenceModalType = infos.type;
      state.occurrenceModalStatus = infos.status;
    },
  },

  actions: {
    changeOccurrenceModalInfos(context, infos) {
      context.commit("setOccurrenceModalInfos", infos);
    },
  },
};
