import Vue from "vue";
import Vuex from "vuex";

import alertSnackbar from "./modules/widgets/alertSnackbar";
import changeTableStatusModal from "./modules/widgets/changeTableStatusModal";

import captchaModule from "./modules/captchaModule.js";
import routesModule from "./modules/routesModule";
import userModule from "./modules/userModule";
import accountModule from "./modules/accountModule";
import profilesModule from "./modules/profilesModule";
import systemsModule from "./modules/systemsModule";
import typeOfOccurrencesModule from "./modules/typeOfOccurrencesModule";
import occurrencesModule from "./modules/occurrencesModule";
import occurrenceHistoryModule from "./modules/occurrenceHistoryModule";
import scheduleModule from "./modules/scheduleModule";
import userPasswordModule from "./modules/userPasswordModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // WIDGETS
    alertSnackbar,
    changeTableStatusModal,

    // MODULES
    captchaModule,
    routesModule,
    userModule,
    accountModule,
    profilesModule,
    systemsModule,
    typeOfOccurrencesModule,
    occurrencesModule,
    occurrenceHistoryModule,
    scheduleModule,
    userPasswordModule,
  },
});
